<script setup>
import { computed, onMounted, ref } from "vue";

const props = defineProps({
    hasError: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const modelValue = defineModel({ type: String, default: "" });

const input = ref(null);

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus();
    }
});

const classes = computed(() => {
    if (props.hasError) {
        return "text-red-900 ring-red-500 placeholder:text-red-300 focus:ring-red-500";
    }
    return "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-brand-primary-600";
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        ref="input"
        class="block h-10 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
        :class="classes"
        v-model="modelValue"
    />
</template>
